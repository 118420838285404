import * as React from 'react';
import logoTD from '../../../assets/logo_td.svg';
import techDaysLinksData from '../../../content/links/tech-days-links-content.json';
import InternalLink from '../../soul/navigation/InternalLink';
import { Link } from 'gatsby';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
const SiteNavbar: React.FC = () => {

    const getPartiallyActive = (link: string) => {
        return link !=='/';
    };


    const filteredLinks = Object.values(techDaysLinksData.links).filter(link => link.navbar && link.enabled);

    return (
        <nav id="js-site-navbar" className="ew-site-nav">
            <div className="centered-column">
                <div className="site-nav">
                    <InternalLink to={techDaysLinksData.home.href} className="site-nav__logo">
                        <img src={logoTD} alt="Users' meeting logo" className="ew-logo"/>
                    </InternalLink>
                    <div className="site-nav__links">
                        {filteredLinks.map(link =>
                            <div key={link.href} className="site-nav__link">
                                <Link to={link.href} className="a-button a-button--ghost"
                                      partiallyActive={getPartiallyActive(link.href)}
                                      activeClassName= 'a-button--down'>
                                    {link.label}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default SiteNavbar;
